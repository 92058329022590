<template>
  <div class="notif-container">
    <div class="title-row">
        <h3>Push Notifications</h3>
        <Button :disabled="false" text="Add" type="success" size="sm" :onClick="() => addNotification()" :loading="false" />
    </div>
    <div class="table">
      <div class="row row-header">
        <div class="cell cell-header" :style="{ width: 100 / 3 + '%' }">
          Message Id
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 3 + '%' }">
          Schedule Time
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 3 + '%' }">
          Actions
        </div>
      </div>
      <div
        v-for="(notification, index) in notifications"
        v-bind:key="index"
        class="row"
      >
        <div class="cell" :style="{ width: 100 / 3 + '%' }">
          <input v-model="notification.message_id" />
        </div>
        <div class="cell" :style="{ width: 100 / 3 + '%' }">
          <DatePicker
            v-model.number="notification.time"
            mode="dateTime"
            :min-date="Date.now()"
            :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm',
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input :disabled="notification.task_reference" id="time" :value="inputValue" v-on="inputEvents" />
            </template>
          </DatePicker>
        </div>
        <div class="cell" :style="{ width: 100 / 3 + '%' }">
            <Button :disabled="false" text="Remove" type="error" size="sm" :onClick="() => removeNotification(index)" :loading="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import 'v-calendar/dist/style.css';
import Button from '../components/common/Button.vue'
import { ref } from 'vue';
import { generateUUID } from '../util/randomStringGenerator';

export default {
  name: "PushNotificationSchedule",
  components: {
    Button
  },
  props: {
    notifications: {
      type: Array,
      required: true,
    },
    setNotifications: {
        type: Function,
        required: true
    }
  },
  setup(props) {

    const data = ref(JSON.parse(JSON.stringify(props.notifications)))

    const addNotification = () => {
        data.value = JSON.parse(JSON.stringify(props.notifications));
        data.value.push({
            notif_id: generateUUID(),
            message_id: '',
            time: Date.now(),
            task_reference: null
        });
        props.setNotifications(JSON.parse(JSON.stringify(data.value)))
    }

    const removeNotification = (idx) => {
        data.value = JSON.parse(JSON.stringify(props.notifications));
        data.value.splice(idx, 1);
        props.setNotifications(JSON.parse(JSON.stringify(data.value)))
    }

    return {
        props,
        addNotification,
        removeNotification
    };
  },
};

</script>

<style scoped>
.notif-container {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border: 1px solid black;
  margin-bottom: 20px;
  margin-top: 10px;
}

.row {
  flex-grow: 1;
  max-width: 100%;
  display: inline-flex;
  max-height: 70px;
  overflow: hidden;
}

.cell.cell-header {
  font-size: 0.95rem;
  font-weight: 600;
  border-bottom: 1px solid gray;
}

.cell {
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table {
  width: 100%;
  flex-grow: 1;
  max-width: 85vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 15px 0px;
}

.table select {
  width: 90%;
  height: 35px;
}

.table input {
  width: 90%;
  height: 35px;
}

.title-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

</style>
